$(function(){
	// Modal register - add member
	$('.add a').click(function() {
		var clone = $('.member:last').clone(),
			index = parseInt($('.member:last').data('index'))+1,
			cloneDegree = clone.find('.member-degree'),
			cloneFirstName = clone.find('.member-first-name'),
			cloneLastName = clone.find('.member-last-name');
			cloneAccommodation = clone.find('.member-accommodation');
			cloneFoodLimitation = clone.find('.member-food-limitation');
		clone.find('input.text').val('');
		clone.data('index', index);

		cloneDegree.attr('id', 'participantDegrees-participantDegree'+index);
		cloneDegree.attr('name', 'participantDegrees[participantDegree' + index + ']');
		cloneDegree.data('index', index);

		cloneFirstName.attr('id', 'participantFirstNames-participantFirstName'+index);
		cloneFirstName.attr('name', 'participantFirstNames[participantFirstName' + index + ']');
		cloneFirstName.data('index', index);

		cloneLastName.attr('id', 'participantLastNames-participantLastName'+index);
		cloneLastName.attr('name', 'participantLastNames[participantLastName' + index + ']');
		cloneLastName.data('index', index);

		cloneAccommodation.attr('id', 'participantAccommodations-participantAccommodation'+index);
		cloneAccommodation.attr('name', 'participantAccommodations[participantAccommodation' + index + ']');
		cloneAccommodation.data('index', index);

		cloneFoodLimitation.attr('id', 'participantFoodLimitations-participantFoodLimitation'+index);
		cloneFoodLimitation.attr('name', 'participantFoodLimitations[participantFoodLimitation' + index + ']');
		cloneFoodLimitation.data('index', index);

		$('.member:last').append(clone);
	});

});
