$(function(){
	//init filter form
	$('#filter-form-wrapper').on('change', 'form input[type=checkbox]', function() {
		var form = $(this).closest('form');
		form.submitForm();
	});

	$('#filter-form-wrapper').on('filterupdate change', '.filter-options input[type=checkbox]', function() {
		var group = $(this).closest('.filter-item');
		var num = group.find('.filter-options .checked').length;
		group.find('.num').text('('+num+')');
	});

	$(document).on('ajaxComplete', function() {
		$('.filter-options input[type=checkbox]').trigger('filterupdate');
	});

	// filter on references page
	$(document).on('change','#referencesfilter #categories', function() {
		var allProducts = $('#referencesfilter #products option');
		filterProducts(allProducts);
	});

	$(document).on('ready ajaxComplete', function() {
		if($('.multiselect').select2) {
			$('.multiselect').select2();
		}
		var allProducts = $('#referencesfilter #products option');
		filterProducts(allProducts);
	});

	function filterProducts(products) {
		var categories = $('#referencesfilter #categories').val(),
			filtered = null;
		if(products.length > 0 && categories != null) {
			filtered = products.filter(function() {
				var productCategories = $(this).data('category');
				if(productCategories == undefined) return false;
				for(i=0; i<categories.length; i++) {
					var expString = '\\b' + categories[i] + '\\b',
						exp = new RegExp(expString);
					if(exp.test(productCategories)) return false;
				}
				return true;
			});
		}
		products.removeAttr("disabled").show();
		if(filtered != null) {
			filtered.attr("disabled", "disabled").hide();
		}
		if($("#referencesfilter #products").select2) {
			$("#referencesfilter #products").select2("destroy");
			$("#referencesfilter #products").select2();
		}
	}
});