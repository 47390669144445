$(document).ready(function ($) {
	$('.sh-trig').toggler();
	$('.sh-trig-more').toggler({
		hideTrigger: true
	});
	$('.sh-trig-rev').toggler({
		reverse: true
	});

	$('.sh-trig-bar').toggler({
		hideOnDocumentClick: true,
		beforeClick: function(){
			$('.sh-trig.active').trigger('click');
			$('.sh-trig-bar.active').trigger('click');
		}
	});

	$('.sh-trig-bar').on('click', function () {
		var $focus = $('#' + $(this).data('focus'));
		if ($focus.length) {
			$focus.focus();
		}
	});

	// products filter
	$('.filter-sh-trig').toggler({
		slide: true
	});

	$('.filter-sh-trig-all').on('click', function() {
		var filterOptions = $(this).closest('.products-filter').find('.filter-sh-trig');
		if(filterOptions.length) {
			filterOptions.click();
		}
	});
});

function scrollToContent() {
	var offset = 0;
	if (settings.offsetScroll) {
		offset = settings.offsetScroll;
	}
	$("html:not(:animated),body:not(:animated)").animate({scrollTop: $(content).offset().top + offset}, 500, function () {
		//window.location.hash = content.attr('id');
	});
}

function scrollToTrigger() {
	var offset = 0;
	if (settings.offsetScroll) {
		offset = settings.offsetScroll + 200;
	}
	$("html:not(:animated),body:not(:animated)").animate({scrollTop: $('[data-content=' + trigger.attr('data-content') + ']').offset().top + offset}, 500, function () {
		//window.location.hash = content.attr('id');
	});
}

(function ($) {
	$.fn.toggler = function (settings) {

		var settings = jQuery.extend({
			showSpeed: 100,
			hideSpeed: 100,
			hideTrigger: false,
			slide: false,
			scrollToContent: false,
			offsetScroll: 0,
			hideOnDocumentClick: false,
			documentClickElement: '',
			hideOnEscape: false,
			beforeClick: function() {},
			afterClick: function() {},
			reverse: false
		}, settings);

		$(this).each(function () {

			var trigger = $(this),
					content = $('#' + trigger.data('content'));

			// Radio nebo checkbox
			if (trigger.is(':radio') || trigger.is(':checkbox')) {
				var name = trigger.attr('name');

				$('[name=' + name + ']').on('change', function () {
					content.hide();
				});
				trigger.on('change', function () {
					$isChecked = (settings.reverse) ? !($(this).is(':checked')) : $(this).is(':checked');
					if ($isChecked) {
						content.fadeIn(settings.showSpeed);
					} else {
						content.hide();
					}
				});
				$(window).on('load', function () {
					$isChecked = (settings.reverse) ? !(trigger.is(':checked')) : trigger.is(':checked');
					if ($isChecked) {
						content.fadeIn(settings.showSpeed);
					}
				});
			}
			// Standardní element
			else {
				trigger.on('click', function () {
					// Before click
					if (settings.beforeClick) {
						settings.beforeClick();
					}

					if (content.is(':hidden')) {
						if (settings.slide) {
							content.slideDown(settings.showSpeed, function () {
								if (settings.scrollToContent) {
									scrollToContent();
								}
							});
						} else {
							content.fadeIn(settings.showSpeed, function () {
								if (settings.scrollToContent) {
									scrollToContent();
								}
							});
						}

						$(this).addClass('active').find('.text').text($(this).attr('data-less'));

						if (settings.hideTrigger) {
							trigger.hide();
						}
					} else {
						if (settings.slide) {
							content.slideUp(settings.hideSpeed, function () {
								if (settings.scrollToContent) {
									scrollToTrigger();
								}
							});
						} else {
							content.fadeOut(settings.hideSpeed, function () {
								if (settings.scrollToContent) {
									scrollToTrigger();
								}
							});
						}
						$(this).removeClass('active').find('.text').text($(this).attr('data-more'));

						if (settings.hideTrigger) {
							$('[data-content=' + trigger.attr('data-content') + ']').fadeIn(settings.hideSpeed);
						}
					}

					// After click
					if (settings.afterClick) {
						settings.afterClick();
					}

					return false;
				});
			}
			// Hide on document click
			if (settings.hideOnDocumentClick) {
				$(document).bind('click', function (e) {
					if ( ! $(settings.documentClickElement).is(e.target) && $(settings.documentClickElement).has(e.target).length === 0 ) {
						if ( ! content.is(e.target) && content.has(e.target).length === 0 ) {
							content.fadeOut();
							trigger.removeClass('active');
						}
					}
				});
			}
			// Escape button
			if (settings.hideOnEscape) {
				$(document).keyup(function (e) {
					if (e.keyCode === 27) {
						content.fadeOut();
					}
				});
			}
		});
	};
})(jQuery);
