$(document).ready(function ($) {

	// Match height
	$('.item-eq').matchHeight();
	$('.services .item .head h2 a').matchHeight();

	// Reasons
	$('.reasons .item:not(.item-main)').hover(function() {
		var id = $(this).attr('data-id');
		$('#'+id).attr('class','piece active');
	},function() {
		var id = $(this).attr('data-id');
		$('#'+id).attr('class','piece');
	});

	// Faq
	$('.faq-bar .item, .accessories-bar .item, .references-bar .item').each(function() {
		var item = $(this);
		var head = $(this).find('.item-head');

		head.click(function() {
			if ( ! item.hasClass('active') ) {
				item.addClass('active');
			}
			else {
				item.removeClass('active');
			}
		});
	});

	// Tree
	$('.tree .item').each(function(i) {
		var item = $(this);

		if ( i > 1 ) {
			var hItem = item.position().top;
			var itemPrev = item.prev().prev();
			var hItemPrev = itemPrev.outerHeight();
		}

		$(window).on('load scroll',function() {
			if ( inViewport(item) ) {
				item.addClass('active');
			}

			/*if (i % 2 === 0) {
				item.css({ marginTop: -(hItemPrev) });
			}*/

		});
	});

	// Drop bar
	$('.drop-bar .close').click(function() {
		var id = $(this).closest('.drop-bar').attr('id');
		//$('a[data-content="'+ id +'"]').click();
		$('body').click();
	});

	/**********************************************
	MOBILE NAVIGATION
	**********************************************/

	$(function() {
		var navBtn = $('.nav-btn');
		var mobileNav = $('.mobile-nav');
		var mobileNavOverlay = $('.mobile-nav-overlay');

		function showNav() {
			mobileNav.addClass('active');
			navBtn.addClass('active');
			mobileNavOverlay.fadeIn();
			$('body').css({'overflow': 'hidden'});
		}

		function hideNav() {
			mobileNav.removeClass('active');
			navBtn.removeClass('active');
			mobileNavOverlay.fadeOut();
			$('body').css({ 'overflow': 'auto' });
		}

		$('.link-nav-btn').click(function() {
			if (! mobileNav.hasClass('active')) {
				showNav();
			}
			else {
				hideNav();
			}
			return false;
		});

		// Mobile nav close
		$('.mobile-nav .close').click(function(){
			hideNav();
		});

		// Mobile nav overlay close
		$('.mobile-nav-overlay').click(function(){
			hideNav();
		});

		$('.mobile-nav li a').each(function() {
			if ( $(this).siblings('ul').length > 0 ) {
				$(this).addClass('link-more');
				$(this).append('<span class="more"></span>');
			}
		});

		// Mobile nav accordion
		$('.mobile-nav li a').click(function() {
			if ($(this).siblings('ul').length > 0) {
				if (!$(this).siblings('ul').hasClass('active')) {
					$(this).addClass('active');
					$(this).siblings('ul').addClass('active');
				} else {
					$(this).removeClass('active');
					$(this).siblings('ul').removeClass('active');
				}
				return false;
			}
		});
	});

	/**********************************************
	RESIZE END / SCROLL END
	**********************************************/

	var resizeEnd;
	$(window).on('resize', function () {
		clearTimeout(resizeEnd);
		resizeEnd = setTimeout(function () {
			$(window).trigger('resizeEnd');
		}, 100);
	});

	var scrollEnd;
	$(window).on('scroll', function () {
		clearTimeout(scrollEnd);
		scrollEnd = setTimeout(function () {
			$(window).trigger('scrollEnd');
		}, 100);
	});

});

function jQuerySelectorEscape(expression) {
	return expression.replace(/[!"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~]/g, '\\$&');
}

// Zobrazení elementu ve viewportu
function inViewport(el) {
	var elH = $(el).height(),
			elTop = $(el).offset().top,
			scrollTop = $(window).scrollTop(),
			winH = $(window).height();
			fixedHeaderH = 0; // výška fixní hlavičky

	return ((elTop + elH) > (scrollTop + fixedHeaderH) && (scrollTop - elH) > (elTop - winH));
}

/**********************************************
YOUTUBE BG VIDEO
**********************************************/

if ($('#tv').length > 0) {
	var tag = document.createElement('script');
	tag.src = 'https://www.youtube.com/player_api';
	var firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
	var tv, playerDefaults = {autoplay: 0, autohide: 1, modestbranding: 0, rel: 0, showinfo: 0, controls: 0, disablekb: 1, enablejsapi: 0, iv_load_policy: 3};
	var vid = [
				{'videoId': $('#tv').attr('data-id'), 'startSeconds': 0, 'endSeconds': 690, 'suggestedQuality': 'hd720'},
				//{'videoId': '9ge5PzHSS0Y', 'startSeconds': 465, 'endSeconds': 657, 'suggestedQuality': 'hd720'},
			],
			randomVid = Math.floor(Math.random() * vid.length),
		currVid = randomVid;

	$('.hi em:last-of-type').html(vid.length);

	function onYouTubePlayerAPIReady(){
	  tv = new YT.Player('tv', {events: {'onReady': onPlayerReady, 'onStateChange': onPlayerStateChange}, playerVars: playerDefaults});
	}

	function onPlayerReady(){
		tv.loadVideoById(vid[currVid]);
		tv.mute();
	}

	function onPlayerStateChange(e) {
		if (e.data === 1){
			$('#tv').addClass('active');
			$('.hi em:nth-of-type(2)').html(currVid + 1);
		} else if (e.data === 2){
			$('#tv').removeClass('active');
			if(currVid === vid.length - 1){
				currVid = 0;
			} else {
				currVid++;
			}
			tv.loadVideoById(vid[currVid]);
			tv.seekTo(vid[currVid].startSeconds);
		}
	}

	function vidRescale(){
		if(tv != undefined) {
			var w = $(window).width()+200,
				h = $(window).height()+200;

			if (w/h > 16/9){
				tv.setSize(w, w/16*9);
				$('.tv .screen').css({'left': '0px'});
			} else {
				tv.setSize(h/9*16, h);
				$('.tv .screen').css({'left': -($('.tv .screen').outerWidth()-w)/2});
			}
		}
	}

	$(window).on('load resize', function(){
		vidRescale();
	});

	$('.hi span:first-of-type').on('click', function(){
		$('#tv').toggleClass('mute');
		$('.hi em:first-of-type').toggleClass('hidden');
		if($('#tv').hasClass('mute')){
			tv.mute();
		} else {
			tv.unMute();
		}
	});

	$('.hi span:last-of-type').on('click', function(){
		$('.hi em:nth-of-type(2)').html('~');
		tv.pauseVideo();
	});
}