var sliders = [];

$(document).ready(function ($) {

	// Sudo slider
	if ($.fn.sudoSlider ) {

		// Item slider
		$('.item-slider').each(function (index) {
			var id = $(this).attr('id');
			sliders[id] = [];
			var el = $(this);

			el.attr('data-slides') ? sliders[id]['slideNum'] = el.attr('data-slides') : sliders[id]['slideNum'] = 1;
			el.attr('data-slides-desktop') ? sliders[id]['slideNumDesktop'] = el.attr('data-slides-desktop') : sliders[id]['slideNumDesktop'] = sliders[id]['slideNum'];
			el.attr('data-slides-tablet') ? sliders[id]['slideNumTablet'] = el.attr('data-slides-tablet') : sliders[id]['slideNumTablet'] = sliders[id]['slideNumDesktop'];
			el.attr('data-slides-mobile') ? sliders[id]['slideNumMobile'] = el.attr('data-slides-mobile') : sliders[id]['slideNumMobile'] = sliders[id]['slideNumTablet'];
			el.attr('data-auto') ? sliders[id]['slideAuto'] = el.data('auto') : sliders[id]['slideAuto'] = true;
			el.attr('data-nav') ? sliders[id]['slideNav'] = el.data('nav') : sliders[id]['slideNav'] = true;
			el.attr('data-numeric') ? sliders[id]['slideNumeric'] = el.data('numeric') : sliders[id]['slideNumeric'] = true;
			el.attr('data-continuous') ? sliders[id]['slideContinuous'] = el.data('continuous') : sliders[id]['slideContinuous'] = true;
			el.attr('data-effect') ? sliders[id]['slideEffect'] = el.data('effect') : sliders[id]['slideEffect'] = 'fade';
			el.attr('data-speed') ? sliders[id]['slideSpeed'] = el.data('speed') : sliders[id]['slideSpeed'] = 250;

			var panelNum = el.find('.panel').length;

			if (panelNum > sliders[id]['slideNum']) {
				sliders[id]['slider'] = el.find('.slider').sudoSlider({
					effect: sliders[id]['slideEffect'],
					vertical: false,
					auto: sliders[id]['slideAuto'],
					autowidth: false,
					continuous: sliders[id]['slideContinuous'],
					prevNext: sliders[id]['slideNav'],
					numeric: sliders[id]['slideNumeric'],
					slideCount: sliders[id]['slideNum'],
					speed: sliders[id]['slideSpeed'],
					responsive: true,
					customLink: 'a.customLink',
					controlsAttr: 'class="slider-nav"',
					prevHtml: '<a href="#" class="prev"><span class="icon"></span></a>',
					nextHtml: '<a href="#" class="next"><span class="icon"></span></a>'
				});
			}
		});

		$(window).on("resizeEnd focus", function () {
			for (var i in sliders) {
				if (!sliders.hasOwnProperty(i))
					continue;

				var width = $(window).width();

				var orgSlideCount = sliders[i]['slider'].getOption("slideCount");
				var slideCount;

				if (width >= 1200) {
					slideCount = sliders[i]['slideNum']; // 1200 - 1920
				}
				else if (width >= 992) {
					slideCount = sliders[i]['slideNumDesktop']; // 992 - 1199
				}
				else if (width >= 768) {
					slideCount = sliders[i]['slideNumTablet']; // 768 - 991
				}
				else {
					slideCount = sliders[i]['slideNumMobile']; // 0 - 767
				}
				if (slideCount != orgSlideCount) {
					sliders[i]['slider'].setOption("slideCount", slideCount);
				}
			};
		}).resize();
	}

});
